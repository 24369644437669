import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
// @ts-expect-error -- FIX swiper types error
import SwiperCore from 'swiper'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { twMerge } from 'tailwind-merge'
import ArrowButton from './components/arrow'
import styles from './styles.module.css'
import 'swiper/css'
import 'swiper/css/pagination'

type HorizontalSwiperProps = SwiperProps & {
  children: React.ReactNode
  absoluteArrows?: boolean
  showArrows?: boolean
  slideClassName?: string
  containerClassName?: string
  shouldShowArrowsInMobile?: boolean
}

const HorizontalSwiperWithSideArrows = forwardRef(
  (
    {
      children,
      absoluteArrows,
      showArrows = true,
      slideClassName,
      onToEdge,
      onBeforeInit,
      onAfterInit,
      onFromEdge,
      className,
      tag,
      containerClassName,
      shouldShowArrowsInMobile,
      ...restProps
    }: HorizontalSwiperProps,
    ref?: React.ForwardedRef<SwiperCore | undefined>,
  ) => {
    const swiperRef = useRef<SwiperCore>()
    const [isBeginning, setIsBeginning] = useState(true)
    const [isEnd, setIsEnd] = useState(true)
    const swiperTag = tag || 'ul'
    const isScrollable = !(isBeginning && isEnd)
    const hasArrows = showArrows && isScrollable

    const toPrevItem = useCallback(() => {
      if (swiperRef.current) swiperRef.current.slidePrev()
    }, [])

    const toNextItem = useCallback(() => {
      if (swiperRef.current) swiperRef.current.slideNext()
    }, [])

    const update = useCallback((swiper: SwiperCore) => {
      setIsBeginning(swiper.isBeginning)
      setIsEnd(swiper.isEnd)
    }, [])

    const _onToEdge: NonNullable<SwiperProps['onToEdge']> = useCallback(
      swiper => {
        update(swiper)
        if (onToEdge) onToEdge(swiper)
      },
      [onToEdge, update],
    )

    const _onFromEdge: NonNullable<SwiperProps['onFromEdge']> = useCallback(
      swiper => {
        update(swiper)
        if (onFromEdge) onFromEdge(swiper)
      },
      [onFromEdge, update],
    )

    const _onAfterInit: NonNullable<SwiperProps['onAfterInit']> = useCallback(
      swiper => {
        update(swiper)
        if (onAfterInit) onAfterInit(swiper)
      },
      [onAfterInit, update],
    )

    const _onBeforeInit: NonNullable<SwiperProps['onBeforeInit']> = useCallback(
      swiper => {
        swiperRef.current = swiper
        if (onBeforeInit) onBeforeInit(swiper)
      },
      [onBeforeInit],
    )

    useImperativeHandle(ref, () => swiperRef.current)

    return (
      <div
        id='pagination-container'
        className={twMerge('relative flex', containerClassName, styles['horizontal-swiper'])}
      >
        <div className='flex w-full items-center justify-start gap-x-2'>
          {hasArrows && !isBeginning && (
            <ArrowButton
              direction='left'
              onClick={toPrevItem}
              className={twMerge(
                absoluteArrows ? 'absolute left-2' : '',
                shouldShowArrowsInMobile ? '' : 'mobileUp:block hidden',
              )}
            />
          )}
          <Swiper
            spaceBetween={16}
            slidesPerView='auto'
            direction='horizontal'
            simulateTouch
            {...restProps}
            tag={swiperTag}
            className={twMerge('m-0 list-none p-0', className)}
            onBeforeInit={_onBeforeInit}
            onAfterInit={_onAfterInit}
            onToEdge={_onToEdge}
            onFromEdge={_onFromEdge}
          >
            {React.Children.map(children, child => (
              <SwiperSlide tag={swiperTag === 'ul' ? 'li' : 'div'} className={slideClassName}>
                {child}
              </SwiperSlide>
            ))}
          </Swiper>
          {hasArrows && !isEnd && (
            <ArrowButton
              direction='right'
              onClick={toNextItem}
              className={twMerge(
                absoluteArrows ? 'absolute right-2' : '',
                shouldShowArrowsInMobile ? '' : 'mobileUp:block hidden',
              )}
            />
          )}
        </div>
      </div>
    )
  },
)

HorizontalSwiperWithSideArrows.displayName = 'HorizontalSwiperWithSideArrows'

export default HorizontalSwiperWithSideArrows
